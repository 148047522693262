import { isAnonRuBot } from "../util/is-anonrubot";
import { PERSIST_USER_ID_KEY } from "../constants/commonConstants";
import {useState, useEffect, useMemo} from "react";
import {useSearchParams} from "react-router-dom";

export function useBotUrl() {
  const baseUrl = isAnonRuBot()
    ? `https://bots.anonchat.io`
    : `https://premium.anonchatbot.com`;
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState(
    localStorage.getItem(PERSIST_USER_ID_KEY)
  );

  useEffect(() => {
    const searchParamsUserId = searchParams.get('userId');
    if (searchParamsUserId) {
      localStorage.setItem(PERSIST_USER_ID_KEY, searchParamsUserId);
      setUserId(searchParamsUserId);
    }
  }, [searchParams])

  return useMemo(() => {
    const url = new URL(baseUrl);
    if (userId) {
      url.searchParams.set("userId", userId);
    }
    return url
  }, [baseUrl, userId]);
}
