import React from "react";
import FAQItem from "components/FAQItem";
import "assets/styles/faq-page.scss";

import { FAQListExtended } from "constants/FAQ";
import { useI18n } from "i18n/use-i18n";
import {useFAQTextParams} from "../hooks/use-faq-text-params";

function FAQ() {
  const { translate } = useI18n();
  const textParams = useFAQTextParams()
  return (
    <div className="faq section">
      <div className="container">
        <h1 className="title title--xl">Вопрос-ответ</h1>
        {FAQListExtended.map(({ title, text }, index) => (
          <FAQItem
            key={index}
            title={translate(title)}
            text={translate(text, textParams?.[text] ?? [])}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default FAQ;
