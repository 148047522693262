import { useEffect, useState } from "react";
import useLocalStorageState from "use-local-storage-state";

export function useColorScheme() {
  const [userPrefersDark, setUserPrefersDark] = useLocalStorageState("colorScheme", {
    defaultValue: null,
  });
  const [systemPreference, setSystemPreference] = useState(null);
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => {
      const newPreference = e.matches ? "dark" : "light";
      setSystemPreference(newPreference);
    };
    // Set initial value
    handleChange(mediaQuery);
    // Listen for changes
    mediaQuery.addEventListener("change", handleChange);
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  const effectiveDarkMode =
    userPrefersDark !== null ? userPrefersDark : systemPreference === "dark";

  useEffect(() => {
    if (effectiveDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [effectiveDarkMode]);

  return {
    isDark: effectiveDarkMode,
    setIsDark: (newValue) => {
      // Only update if the user explicitly chooses a theme
      if (typeof newValue === "boolean") {
        setUserPrefersDark(newValue);
      }
    },
  };
}
