const subscriptionVkDescription = [
  "subscriptions_vk_about1",
  "subscriptions_vk_about2",
  "subscriptions_vk_about5",
  "subscriptions_vk_about6",
];

const subscriptionTelegramDescription = [
  "subscriptions_telegram_about1",
  "subscriptions_telegram_about2",
  "subscriptions_telegram_about3",
  "subscriptions_telegram_about4",
];

export const subscriptionsListVk = [
  {
    price: "149₽",
    period: "subscriptions_week_sale",
    period_purchase_page: "one_week",
    description: subscriptionVkDescription,
  },
  {
    price: "249₽",
    period: "subscriptions_month_sale",
    period_purchase_page: "one_month",
    description: subscriptionVkDescription,
  },
  {
    price: "999₽",
    period: "subscriptions_year_sale",
    period_purchase_page: "one_year",
    description: subscriptionVkDescription,
  },
  {
    price: "499₽",
    period: "subscriptions_year_sale",
    period_purchase_page: "one_year",
    description: subscriptionVkDescription,
  },
];

export const subscriptionsListTelegram = [
  {
    price: "199₽",
    priceUsd: "1.99$",
    period: "subscriptions_week",
    period_purchase_page: "one_week",
    description: subscriptionTelegramDescription,
  },
  {
    price: "399₽",
    priceUsd: "3.99$",
    period: "subscriptions_month",
    period_purchase_page: "one_month",
    description: subscriptionTelegramDescription,
  },
  {
    price: "1499₽",
    priceUsd: "14.99$",
    period: "subscriptions_year",
    period_purchase_page: "one_year",
    description: subscriptionTelegramDescription,
  },
  {
    price: "4999₽",
    priceUsd: "69$",
    period: "subscriptions_vip",
    period_purchase_page: "one_year",
    description: [
      ...subscriptionTelegramDescription,
      "subscriptions_telegram_about5",
      "subscriptions_telegram_about6",
    ],
  },
  {
    price: "149₽",
    priceUsd: "1.49$",
    period: "subscriptions_week",
    period_purchase_page: "one_week",
    description: subscriptionTelegramDescription,
  },
  {
    price: "299₽",
    priceUsd: "2.99$",
    period: "subscriptions_month",
    period_purchase_page: "one_month",
    description: subscriptionTelegramDescription,
  },
  {
    price: "999₽",
    priceUsd: "11.99$",
    period: "subscriptions_year",
    period_purchase_page: "one_year",
    description: subscriptionTelegramDescription,
  },
  {
    price: "699₽",
    priceUsd: "6.99$",
    period: "subscriptions_year",
    period_purchase_page: "one_year",
    description: subscriptionTelegramDescription,
  },
];
