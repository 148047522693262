import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/global.scss";

import Layout from "components/layout";
import LayoutPayment from "components/layout/LayoutPayment";
import PaymentVk from "pages/PaymentVK";
import PaymentTelegram from "./pages/PaymentTelegram";
import Home from "pages/Home";
import Contacts from "pages/Contacts";
import FAQ from "pages/FAQ";
import BotTelegram from "pages/BotTelegram";
import BotVk from "pages/BotVk";
import SubscriptionsTelegram from "pages/SubscriptionsTelegram";
import SubscriptionsVk from "pages/SubscriptionsVk";
import CancelSubscription from "./pages/CancelSubscription";
import { ScrollToTop } from "./components/ScrollToTop";
import NotFound from "components/NotFound";
import { CanonicalLink } from "components/CanonicalLink";

const StaticHtmlPage = ({ pageName }) => {
  React.useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.search;
    window.location.href = `/${pageName}.html${params}`;
  }, [pageName]);
  return null;
};

const staticRoutes = [
  "anonrubot_privacy",
  "pay_stripe_ru",
  "pay_stripe_test_ru",
  "pay_stripe_test",
  "pay_stripe",
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <CanonicalLink />
      <Routes>
        {staticRoutes.map((route) => (
          <Route
            key={route}
            path={`/${route}`}
            element={<StaticHtmlPage pageName={route} />}
          />
        ))}

        <Route
          element={
            <Layout>
              <ScrollRestoration />
            </Layout>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="bot/telegram" element={<BotTelegram />} />
          {/* Temporary duplicate page requested by SEO consulstant */}
          <Route path="bot/tg" element={<BotTelegram />} /> 
          <Route path="telegram" element={<BotTelegram />} />
          <Route path="bot/vk" element={<BotVk />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="cancel-subscription" element={<CancelSubscription />} />
          <Route
            path="subscriptions/telegram"
            element={<SubscriptionsTelegram />}
          />
          <Route path="subscriptions/vk" element={<SubscriptionsVk />} />
        </Route>

        <Route element={<LayoutPayment />}>
          <Route path="payment-vk" element={<PaymentVk />} />
          <Route path="payment-telegram" element={<PaymentTelegram />} />
          <Route
            path="payment-month-179"
            element={<PaymentTelegram period_purchase_page="one_month" />}
          />
          <Route
            path="payment-week-129"
            element={<PaymentTelegram period_purchase_page="one_week" />}
          />
          <Route
            path="payment-year-699"
            element={<PaymentTelegram period_purchase_page="one_year" />}
          />
          <Route
            path="payment-year-999"
            element={<PaymentTelegram period_purchase_page="one_year" />}
          />
          <Route
            path="payments-year-4999"
            element={<PaymentTelegram period_purchase_page="one_year" />}
          />
          <Route
            path="subscribe-sale-699"
            element={<PaymentTelegram period_purchase_page="one_year" />}
          />
          <Route
            path="subscribe-vip-4999"
            element={<PaymentTelegram period_purchase_page="one_year" />}
          />
          <Route
            path="subscribe-year-sale-999"
            element={<PaymentTelegram period_purchase_page="one_year" />}
          />
          <Route
            path="payment-year-999"
            element={<PaymentTelegram />}
            state={{ period_purchase_page: "one_year" }}
          />
          <Route
            path="payments-year-4999"
            element={<PaymentTelegram />}
            state={{ period_purchase_page: "one_year" }}
          />
          <Route
            path="subscribe-sale-699"
            element={<PaymentTelegram />}
            state={{ period_purchase_page: "one_year" }}
          />
          <Route
            path="subscribe-vip-4999"
            element={<PaymentTelegram />}
            state={{ period_purchase_page: "one_year" }}
          />
          <Route
            path="subscribe-year-sale-999"
            element={<PaymentTelegram />}
            state={{ period_purchase_page: "one_year" }}
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
