import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export const CanonicalLink = () => {
  const location = useLocation();
  const pathWithoutSlash = location.pathname.replace(/\/$/, '');
  const canonicalUrl = `${window.location.protocol}//${window.location.host}${pathWithoutSlash}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};